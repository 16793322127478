import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './types';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';

export const useGetPasswordProtectionStatus = ({ siteId }: { siteId: string }) => {
  return useQuery({
    queryKey: ['site-get-password-protection-status', siteId],

    queryFn: async () =>
      await AxiosService.get<components['schemas']['GetPasswordProtectionResponse']>(
        `sites/${siteId}/password_protection`
      ),

    staleTime: 5000,
    gcTime: 10,
  });
};

export type PasswordProtectionUser = components['schemas']['PasswordProtectionUser'];

export const usePasswordProtectionUsers = (siteId: number | string, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['password_protection/users', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListPasswordProtectionUsersResponse']>(
        `sites/${siteId}/password_protection/users?${serializedParams}`
      ),
  });
};

export const useSetSiteProtection = ({ siteId }: { siteId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`site-get-password-protection-status-${siteId}-users`],

    mutationFn: async (enabled: boolean) =>
      enabled
        ? await AxiosService.post<components['schemas']['SuccessResponse']>(
            `sites/${siteId}/password_protection`
          )
        : await AxiosService.delete<components['schemas']['SuccessResponse']>(
            `sites/${siteId}/password_protection`
          ),

    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['site-get-password-protection-status', siteId] }),
  });
};

export type CreateUserSchema = { username: string; password: string };

export const useAddSiteProtectionUser = ({ siteId }: { siteId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`site-get-password-protection-status-${siteId}-add-user`],

    mutationFn: async ({ username, password }: CreateUserSchema) =>
      await AxiosService.post<components['schemas']['SuccessResponse']>(
        `sites/${siteId}/password_protection/users`,
        {
          username,
          password,
        }
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`site-get-password-protection-status-${siteId}-users`],
      });
    },
  });
};

export const useDeleteSiteProtectionUser = ({ siteId }: { siteId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`site-get-password-protection-status-${siteId}-delete-user`],

    mutationFn: async (userId: string) =>
      await AxiosService.delete<components['schemas']['SuccessResponse']>(
        `sites/${siteId}/password_protection/users/${userId}`
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`site-get-password-protection-status-${siteId}-users`],
      });
    },
  });
};

export const useEnableLockSite = ({ siteId }: { siteId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await AxiosService.post<components['schemas']['SuccessResponse']>(`sites/${siteId}/lock`),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

export const useDisableLockSite = ({ siteId }: { siteId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await AxiosService.delete<components['schemas']['SuccessResponse']>(`sites/${siteId}/lock`),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};
